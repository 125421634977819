import { CONSTANTS } from '../api/constants';
let initState = {
    status: '',
    error: null,
    log: [],
    totalIn: {
        total: 0,
        doors: [],
        data: []
    },
    totalOut: {
        total: 0,
        doors: [],
        data: []
    }
}

export function dashboard(state = initState, action) {
    //console.log(action)
    // let __state = { ...state }
    switch (action.type) {
        case CONSTANTS.DASHBOARD.DATA:
            return { ...state, ...action.payload }
        case CONSTANTS.CLEAR:
            return initState
        default:
            return state
    }
}
