import { combineReducers } from 'redux';
import { dashboard } from './dashboard'
import { doors } from './doors';
import { logs } from './logs';
import { users } from './users';
import { timezone } from './timezone';

const rootReducer = combineReducers({
  dashboard,
  doors,
  logs,
  users,
  timezone
});

export default rootReducer;